import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <h1>My Stuff</h1>

      <ul>
        <li>
          My woefully neglected <Link to="/blog">Blog</Link>
        </li>
        <li>
          <a rel="me" href="https://mastodon.social/@dorward/">
            @dorward@mastodon.social
          </a>
        </li>
        <li>
          <a href="http://dorward.co.uk/">Professional Landing Page</a>
        </li>
        <li>
          <a href="https://github.com/dorward">Github Profile</a>
        </li>
        <li>
          <a href="https://dorward.github.io/qr-web/">QR Code Generator</a>
        </li>
      </ul>
    </Layout>
  )
}

export const Head = () => (
  <link rel="me" href="https://mastodon.social/@dorward/" />
)

export default IndexPage
